import React, { useState, useEffect } from "react";
import {
  Spin,
  Button,
  Form,
  Input,
  Upload,
  Table,
  Modal,
  Col,
  Row,
  Select,
  InputNumber,
  Typography,
  Space,
} from "antd";
import {
  rule_bigger_than_5b, rule_float_number,
  rule_number,
  rule_require,
} from "../../../../shared/validateInput";
import {
  COMBINE_RATE,
  COMBINE_TYPE,
  DANGER_OPTION,
  DELIVERY_TYPE,
  INSURANCE_VALUE_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  ORIGIN_OPTION,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  SPECIFICATION_TYPE,
  VEHICLE_OPTION,
} from "../../../../shared/define";
import {
  beforeUpload,
  onPreview,
  propsImage,
} from "../../../../shared/imageHandle";
import ImgCrop from "antd-img-crop";
import {
  LeftOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../../shared/api/api";
import TypedInputNumber from "antd/es/input-number";

const { Title, Text } = Typography;
const { Option } = Select;
function UpdateHistoryOrder() {
  const history = useHistory();
  let { id }: any = useParams();
  const [form] = Form.useForm();
  const [vehicleType, setVehicleType] = useState<any>("");
  const [combineRate, setCombineRate] = useState("");
  const [combineExpenseRate, setCombineExpenseRate] = useState("");
  const [spinning, setSpinning] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [combinations, setCombinations] = useState<any[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setSpinning(true);
    const response = await API.get(`admin/history-order/${id}`).then(
      (res) => res.data
    );
    if (response.success) {
      setSelectedItem(response.data);
      form.setFieldsValue(response.data);
      setVehicleType(response.data.vehicle.name);
      setCombineRate(response.data.combinedRate);
      setCombineExpenseRate(response.data.combinedExpenseRate);
    }
    setSpinning(false);
  };

  const onUpdate = async (value: any) => {
    const response = await API.put(`admin/history-order/${id}`, value).then(
      (res) => res.data
    );
    if (response.success) {
      history.push("/order/history");
    }
  };

  // useEffect(() => {
  //   if (selectedItem) {
  //     fetchFreightCombination();
  //   }
  // }, [selectedItem]);

  const fetchFreightCombination = async () => {
    const searchParams = {
      receipt: selectedItem?.routes?.[0]?.receipt?.[0].place,
      delivery: selectedItem?.routes?.[0]?.delivery?.[0]?.place,
      customer: selectedItem?.customer,
    };
    const response: any = await API.get("admin/freight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      setCombinations(data?.data);
    }
  };

  return (
    <Spin spinning={spinning}>
      <Form form={form} layout="vertical" onFinish={onUpdate}>
        <Title level={5} style={{ marginBottom: 8 }}>
          Thông tin vận chuyển
        </Title>
        <Row gutter={16}>
          {/*<Col span={6}>*/}
          {/*  <Form.Item label="Tuyến" name={"freight"} rules={[rule_require]}>*/}
          {/*    <Select placeholder="Chọn Tuyến">*/}
          {/*      {combinations?.map((item) => {*/}
          {/*        return (*/}
          {/*          <Option*/}
          {/*            value={item._id}*/}
          {/*          >{`[${item?.code}] ${item?.description}`}</Option>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col span={6}>
            <Form.Item
              label="Loại xe"
              name={["vehicle", "name"]}
              rules={[rule_require]}
            >
              <Select
                placeholder="Chọn loại xe"
                onSelect={(type: string) => setVehicleType(type)}
              >
                {VEHICLE_OPTION.map((item: any, index: any) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {vehicleType === VEHICLE_OPTION[0].value && (
            <Col span={6}>
              <Form.Item
                label="Loại Container"
                name={["vehicle", "numberOfFeets"]}
                rules={[rule_require]}
              >
                <Select placeholder="Chọn Loại Container">
                  {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {vehicleType === VEHICLE_OPTION[0].value && (
            <Col span={6}>
              <Form.Item label="Số cont" name={["vehicle", "container"]}>
                <Input placeholder="Nhập số container" />
              </Form.Item>
            </Col>
          )}
          {vehicleType === VEHICLE_OPTION[1].value && (
            <Col span={6}>
              <Form.Item
                label="Tải trọng"
                name={["vehicle", "payload"]}
                rules={[rule_require]}
              >
                <Select placeholder="Chọn tải trọng">
                  {PAYLOAD_OPTION.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Title level={5} style={{ marginBottom: 8 }}>
          Thông tin hàng hóa
        </Title>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Quy cách"
              name="specification"
              // rules={[rule_require]}
            >
              <Select placeholder="Chọn quy cách">
                {SPECIFICATION_TYPE.map((item: any, index: any) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Hình thức giao nhận"
              name="deliveryForm"
              rules={[rule_require]}
            >
              <Select placeholder="Chọn hình thức giao nhận">
                {DELIVERY_TYPE.map((item: any, index: any) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Chỉ số kết hợp doanh thu"
              name="combinedRate"
              // rules={[rule_float_number]}
            >
              <Select
                {...(combineRate != "CY" && { suffixIcon: "%" })}
                onChange={(value: any) => setCombineRate(value)}
              >
                {COMBINE_RATE.map((item: any) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Chỉ số kết hợp chi phí"
              name="combinedExpenseRate"
              // rules={[rule_float_number]}
            >
              <Select
                {...(combineExpenseRate != "CY" && { suffixIcon: "%" })}
                onChange={(value: any) => setCombineExpenseRate(value)}
              >
                {COMBINE_RATE.map((item: any) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Title level={5} style={{ marginBottom: 8 }}>
          Thông tin khoảng cách
        </Title>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Km có hàng" name={["routes", 0, "haveGoodsDistance"]} rules={[rule_float_number]}>
              <Input placeholder={"Nhập km có hàng"} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Km không hàng" name={["routes", 0, "noGoodsDistance"]} rules={[rule_float_number]}>
              <Input placeholder={"Nhập km không hàng"} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Km phát sinh" name={["routes", 0, "incurredDistance"]} rules={[rule_float_number]}>
              <Input placeholder={"Nhập km phát sinh"} />
            </Form.Item>
          </Col>

        </Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#03A9F4",
              color: "white",
              marginTop: 12,
            }}
          >
            Cập nhật
          </Button>
        </Col>
      </Form>
    </Spin>
  );
}

export default UpdateHistoryOrder;
